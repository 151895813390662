import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="실시간 녹음기록 소개" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Home" />
          </Link>
          <BlogHeader
            title="실시간 녹음기록 소개"
            date="2021년 10월 1일"
          />
          <p>
            회사생활은 수많은 커뮤니케이션을 하면서 이루어집니다. 우리는 
            많은 회의록을 작성하고 이메일을 쓰고 의견을 교환합니다.
          </p>
          <p>
            바쁜 일정과 수백 가지 아이디어가 머릿속에서 경쟁하면서 
            우리는 중요한 정보를 건너뛰기 쉽습니다.  
          </p>
          <p>
            많은 분들을 위해 VoxRec은 이미 정보를 수집하고 조치를 취해야 하는 회의, 
            이메일, 상담, 인터뷰 및 기타 작업의 기록을 적극 지원하고 있습니다.
          </p>
          <p>
            아직 일부 사람들은 일상 생활이나 회사생활에 있어 음성 텍스트 변환 기술을 써보지 못한 것은 사실입니다.
            그로 인해 아직도 사람들은 중요한 업무 프로세스나 중요한 포인트를 놓치기 쉽습니다. 
          </p>
          <p>
          많은 사람들에게 음성 커뮤니케이션은 도전입니다. 장애가 있는 사람들, 난독증 및 서로의 모국어를 
          구사하지 못하는 사람들은 모두 우리가 말하는 것을 이해하는 데 문제가 있습니다. 
          그러나 이런 커뮤니케이션 오류는 쉽게 해결할 수 있습니다. 
          </p>
          <p>
           우리는 이제 기존의 음성-텍스트 서비스를 다음과 같이 확장하고 있습니다.
           VoxRec 앱에서 실시간 녹음 기록을 소개합니다. 여러분의 녹음은
           평소와 같이 캡처하지만 녹음기록은 실시간으로 화면에 생성됩니다.
          </p>
          <p>
            우리는 해당 기능이 특히 예상하기 어려운 일상 환경과 업무환경에 
            적용될때 사용자에게 더 많은 도움이 되기를 바랍니다.
          </p>
          <BlogBanner
            title="실시간 녹음기록 소개"
            text="통역사 없이 그리고 기다림 없이 외국어로 말하는 내용을 보고 나의 모국어로 보고 읽어보세요. VoxRec의 실시간 녹음기록을 사용해 보세요."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec on iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>음성을 텍스트로 변환하는 디지털 접근성</h2>
          <p>
            세계보건기구(WHO)에 따르면 전 세계적으로 
            약 4억 6600만명의 청각 장애인이 있습니다.
          </p>
          <p>
            전 세계 국가들은 평등을 위해 그 어느 때보다 여러 기획을 추진하고 있습니다.
            실시간 녹음기록 앱은 이러한 비전을 실현할 수 있습니다.
          </p>
          <p>
            타자를 치거나 받아쓰는 데 어려움을 겪는 수백만 명의 다른 사람들이 있습니다.
            난독증, 외상 또는 기타 손의 움직임을 제한하거나 인지에 영향을 미치는 장애가 있는 경우 실시간 녹음기록은
            이러한 사람들이 온라인 또는 오프라인 대화, 회의 및 이벤트에 적용하는 데 도움이 될 수 있습니다.
          </p>
          <h2>국제 회의 및 컨퍼런스에서 실시간 녹음기록의 적용</h2>
          <p>
            우리는 대부분의 회의와 컨퍼런스에 다른 국가의 사람들이 참여하는 시대에 살고 있습니다. 
            실시간 녹음기록은 국제 이사회 회의의 전문가들이 논의 중인 내용을 듣고 볼 수 있도록 도와줍니다. 
            그래야 외국어를 이해하려고 하기보다 주제와 아이디어에 집중할 수 있습니다.
          </p>
          <h2>수업 및 강의 시간에 실시간 자막 제공</h2>
          <p>
            강사와 학생들은 수업에 AI 기반 음성 인식 기술을 구현하면 
            훨씬 더 효율적으로 수업에 참가할 수 있습니다.
          </p>
          <p>
            장애가 있거나 구어에 능숙하지 않은 학생은 VoxRec을 사용하여 강사가 말하는 내용을 
            실시간으로 텍스트로 볼 수 있습니다. 그것은 학생들이 프레젠테이션의 맥락을 더 잘 배우고, 
            이해하고, 반응하는 데 도움이 될 것입니다.
          </p>
          <p>
           수업이 끝나면 VoxRec에서 학습 노트로 사용할 수 있는 
           정확한 성적표를 이메일로 보내드립니다.
          </p>
          <p>
            마찬가지로 회의 및 프레젠테이션에서 라이브 캡션을 사용하여 연사의 말을 
            자세히 설명할 수 있습니다. 이는 청각 장애인을 위한 번역기가 없을 때 매우 유용합니다.
          </p>
          <h2>VoxRec 실시간 녹음기록을 사용해 보기</h2>
          <p>
            VoxRec 앱의 녹음 화면에서 실시간 녹음기록 기능을 활성화하고 사용해 볼 수 있습니다. 
            로봇 얼굴(🤖)을 탭하여 자연스럽게 말하고 화면에 나타나는 텍스트를 보면서 말해 보세요. 
            네, 그렇게 쉽습니다.
          </p>
          <p>
           화면에서 바로 글꼴 크기를 조정하여 텍스트를 읽기 쉽도록 할 수 있습니다.
          </p>
          <p>
            녹음을 중지하면 오디오와 스크립트를 모두 검토하거나 내보낼 수 있습니다.
          </p>
          <p>
            메모 실시간 녹음기록은 사전 녹음된 오디오의 일반 클라우드 기록을 수행할 때와 
            같은 방식으로 자동으로 이메일로 전송될 수 없습니다.
          </p>
          <p>
            VoxRec에 대해 더 자세히 알고 싶으시면 웹사이트의 {' '}
            <a href="/faq">자주묻는질문</a>섹션을 이용하거나 VoxRec 앱을 확인해 보세요.
          </p>
          <p>
            새로운 녹음기록 기능에 대해서 여러분의 생각을 공유해 주세요. 
            - 저희는 여러분의 피드백을 언제든지 환영합니다!
          </p>
        </div>
      </section>
    </LiveChatLayout>

  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
